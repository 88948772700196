.why-us{
    display: flex;
    // align-items: center;
    justify-content: center;
    padding: 0 2rem;
    margin-bottom: 10px;
}
.left-side{
    flex-basis: 50%;
    gap: 1rem;
    display: flex;
    
}
.left-side img{
    width: 200px;
    height: 520px;
}
.left-side div .image2{
   width: 415px;
   height: 300px;
   margin-bottom: 1rem;
}
.small-img{
    display: flex;
    flex-basis: 50%;
    
}
.small-img img{
   height: 200px;
   margin-right:1rem;
}
.right-side{
    flex-basis: 50%;
    margin-left: 30px;
}

.right-side p{
    margin-top: -2px;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--orange);
}
.why-us-heading{
    margin-top: -20px;
    display: flex;
    gap: .8rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    // color: white;
}
.why-us-heading .second-h1{
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}
.expertis{
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    // float: left;
    // justify-content: flex-start;
}
.expertis ul {
    list-style: none;
    
}
.expertis ul li{
    font-size: 1rem;
    display: flex;
    align-items: center;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}
.expertis ul li img{
    margin-right: 10px;
    width: 2rem;
}
.brands{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}
.brands small{
    font-size: 1.2rem;
    color: var(--gray);
    text-transform: uppercase;
}
.partner-img{
    margin-top: 20px;
}
.partner-img img{
    margin-right: 10px;
    width: 3rem;
}



@media screen and (max-width : 768px) {
    .why-us{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .left-side{
        display: none;
    }
    .right-side{
        // padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
       
    }
    .why-us-heading {
        // display: block;
        // display: flex;
        // align-items: flex-start;
        // justify-content: flex-start;
        // margin-left: -2rem;
    }
    .why-us-heading h1{
        font-size: 2rem;
    }
    .expertis{
        position: relative;
        >ul{
            max-width: 280px;
            display: block;
            position: relative;
            // text-align: center;
            margin-left: -10px;
            
        }   
    }
    .brands{
        display: block;
        margin-top: 20px;
        >small{
            font-size: 1rem;
        }
    }
    .partner-img img{
        width: 40px;
    }
    
}