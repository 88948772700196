.footer hr {
    width: 100%;
    // height: 2rem;
    border: 1px solid white;
}

.social-icons {
    padding: 5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
}

.social-icons img {
    width: 2rem;
    margin: 0 30px;
    cursor: pointer;
}

.footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 7rem;

    >img {
        width: 10rem;
    }
}

.blure-footer-1 {
    left: 15%;
    bottom: 0;
    top: 222rem;
    width: 26rem;
    height: 12rem;
    filter: blur(150px);
    background: rgb(255, 115, 0);
}

.blure-footer-2 {
    right: 15%;
    bottom: 0;
    top: 222rem;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}


@media screen and (max-width : 768px) {

    .blure-footer-1 {
        display: none;
    }

    .blure-footer-2 {
        display: flex;
        align-items: center;
        justify-items: center;
        // left: 0;
        right: 1rem;
    }
}