.testimonial{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}
.left-t
{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    color: white;
    text-transform: uppercase;
    gap: 2rem;
}
.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.left-t>:nth-child(2), .left-t>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 2rem;
}
.right-t{
    position: relative;
    flex: 1;
    
}
.right-t>img{
    position: absolute;
    top: 2rem;
    object-fit: cover;
    width: 17rem;
    height: 20rem;
    right: 8rem;
}
.right-t>:nth-child(1){
   position: absolute;
   top: .9rem;
   width: 17rem;
   height: 20rem;
   border: 2px solid orange;
   right: 9rem;
   background: transparent;
}
.right-t>:nth-child(2){
    position: absolute;
    top: 3rem;
    right: 7rem;
    width: 17rem;
    height: 20rem;
    background: var(--planCard);
}
.arrow{
    display: flex;
    position: absolute;
    gap: 2rem;
    bottom: 1rem;
    left: 3rem;
}
.arrow>img{
    width: 1.5rem;
    cursor: pointer;
}


@media screen and (max-width : 768px) {
        .testimonial{
            display: block;
        }
        .left-t>:nth-child(2), .left-t>:nth-child(3){
            font-size: 2rem;
        }
     

        .right-t>:nth-child(1), .right-t>:nth-child(2){
           display: none;
        }
        .right-t>:nth-child(3){
            display: flex;
            align-items: center;
            justify-content: center;
            top: 4rem;
            // left: 0;
            right: 1rem;
        }
        .arrow {
            bottom: -28rem;
            margin: auto;
            // display: block;
            left: 8rem;
            // right: 1r/em;

        }
}