.plans {
    padding: 0 2rem;
}

.plans-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    color: white;

    >h1 {
        text-transform: uppercase;
        font-size: 3rem;
        font-style: italic;
    }
}

.cards {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: -100px;
}

.card-1 {
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    flex-basis: 25%;
    height: 70vh;
    background: var(--caloryCard);
    padding-top: 1rem;
}

.card-1 img {
    width: 30px;

}

.card-1 small {
    display: flex;
    text-transform: uppercase;
    margin-top: 20px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
}

.card-1 p {
    margin-top: 15px;
    font-size: 3rem;
    font-weight: bold;
    color: white;
}

.instruction {
    position: relative;
    margin-top: -10px;
}

.instruction a {
    color: white;
    font-size: .9rem;
    margin-top: 40px;
    cursor: pointer;
    font-weight: normal;
    text-decoration: none;

}

.instruction a:hover {
    text-decoration: underline;
}

.card-1 button {
    background: white;
    margin: 20px 0;
    display: block;
    border: 0;
    padding: 8px 6rem;
    cursor: pointer;
    color: black;
    font-weight: 600;
}

.small-instruction {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;

    >img {
        width: 20px;
        margin-right: 10px;

    }
}

.small-instruction span {
    color: white;
    font-style: 1rem;
}

.card-2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    flex-basis: 25%;
    height: 80vh;
    background: var(--planCard);
    padding-top: 1rem;
    box-shadow: 0 10px 15px rgb(0, 0, 0, 0.4);

    >img {
        width: 50px;
        filter: invert(100%)
    }

    >small {
        display: flex;
        text-transform: uppercase;
        margin-top: 20px;
        font-size: 1rem;
        font-weight: bold;
        color: white;
    }

    >p {
        margin-top: 15px;
        font-size: 3rem;
        font-weight: bold;
        color: white;
    }
}

.better-plan {
    position: relative;
    margin-top: -10px;
}

.better-plan-instruction {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;

    >img {
        width: 25px;
        margin-right: 10px;
    }

    >span {
        font-size: 1.2rem;
        color: white;
    }

}

.better-plan a {
    // margin-top: 20px;
    text-decoration: none;
    color: white;

}

.better-plan a:hover {
    text-decoration: underline;
}

.card-2 button {
    margin-top: 20px;
    padding: 10px 6rem;
    display: block;
    border: none;
    margin: auto;
    background: white;
    color: var(--orange);
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
}
.plans-blure-1{
    width: 32rem;
    height: 20rem;
    left: 0;
    top: 130rem;
}
.plans-blure-2{
    right: 0;
    width: 32rem;
    height: 20rem;
    top: 136rem;
}


@media screen and (max-width : 768px) {
    .plans-blure-1{
        display: none;
    }
    .plans-blure-2{
        display: none;
    }
        .plans-heading{
            display: block;
            text-align: center;
            
            >h1{
                font-size: 1.5rem;
            }
        }
        .cards{
            display: block;
        }
        .card-1{
            flex-basis: 100%;
            height: 28rem;
            padding: 1rem;
            margin-bottom: 20px;
            margin-top: 20px;
            >button{
                margin-top: 30px;
                padding: .8rem 2rem;
                margin: auto;
                display: inline-block;
            }
        }
        .card-2{
            // margin-top: 10px;
            // display: block;
            margin: auto;
            // padding-top: 2rem;
           height: 60vh;
           width: 82%;
            >img{
                width: 30px;
            }
            >small{
                font-size: 1rem;
            }
        }
        .better-plan-instruction span{
            font-size: 1rem;
        }
}